import "core-js/modules/es.object.keys.js";
import { posthog } from 'posthog-js';
import Cookie from 'js-cookie';
export default function (_ref, inject) {
  var $config = _ref.$config,
    route = _ref.route;
  if ($config.POSTHOG_TOKEN) {
    var config = {
      api_host: 'https://eu.posthog.com',
      disable_session_recording: true,
      session_recording: {
        maskTextSelector: '*'
      },
      person_profiles: 'always',
      debug: $config.STAGE === 'development',
      persistence: areCookiesAccepted() ? 'cookie' : 'memory'
    };
    if (route.query.ph_sid && route.query.ph_did) {
      config.bootstrap = {
        sessionID: route.query.ph_sid,
        distinctID: route.query.ph_did,
        isIdentifiedID: false
      };
    }
    posthog.init($config.POSTHOG_TOKEN, config);
  }
  posthog.enable = function () {
    posthog.set_config({
      persistence: 'cookie'
    });
  };
  inject('ph', posthog);
}
function areCookiesAccepted() {
  var cookieConsent = null;
  try {
    cookieConsent = JSON.parse(Cookie.get('CookieConsent'));
    return cookieConsent && cookieConsent.analytics;
  } catch (e) {}
  return false;
}