
import Bowser from 'bowser'
import { getOnlyName } from '~/utils/client'
import { FEATURE_LAW_SERVICES, SUBSCRIPTION_STATUS_ACTIVE, WORKSPACE_TYPE_COMPANY, WORKSPACE_TYPE_CONSUMER } from '~/utils/constants'

export default {
  data () {
    const bowser = Bowser.getParser(window.navigator.userAgent)

    return {
      isIe: bowser.isBrowser('Internet Explorer')
    }
  },
  computed: {
    client () {
      return this.$store.state.auth.client
    },
    company () {
      return this.$store.getters['auth/company']
    },
    subscription () {
      return this.$store.getters['auth/mainSubscription']
    },
    features () {
      return this.$store.state.features
    },
    intercomMimimumContext () {
      const context = {}
      if (this.client) {
        context.user_id = this.client.id
        context.user_hash = this.client.attributes.intercomUserHash
      }
      return context
    },
    intercomFullContext () {
      const context = {}
      if (this.client) {
        context.user_id = this.client.id
        context.name = getOnlyName(this.client)
        context.email = this.client.attributes.email
        context.user_hash = this.client.attributes.intercomUserHash
        context.language_override = this.client.attributes.language

        context.current_workspace = this.client.attributes.name
        context.workspace_country = this.client.attributes.country
        context.workspace_type = WORKSPACE_TYPE_CONSUMER
        context.workspace_subscription_plan = null
        context.workspace_subscription_status = null
        context.workspace_partner = this.client.relationships?.partner?.data?.attributes?.type
        context.workspace_has_law_service_chat = false
      }

      if (this.company) {
        context.current_workspace = this.company.attributes.displayName
        context.workspace_country = this.company.attributes.country
        context.workspace_type = this.company.attributes.name ? WORKSPACE_TYPE_COMPANY : WORKSPACE_TYPE_CONSUMER
        context.workspace_subscription_plan = this.subscription?.attributes.planId
        context.workspace_subscription_status = this.subscription?.attributes.status
        context.workspace_partner = this.company.relationships?.partner?.data?.attributes?.type
        context.workspace_has_law_service_chat = this.$store.getters['auth/isFeatureEnabled'](FEATURE_LAW_SERVICES)

        context.company = {
          id: this.company.id,
          name: this.company.attributes.displayName
        }
      }

      return context
    }
  },
  watch: {
    '$route.fullPath': {
      immediate: true,
      handler (fullPath) {
      // trigger intercom update when path changes
        this.$intercom.call('update', this.intercomMimimumContext)
        this.$ph.capture('$pageview')
      }
    },
    intercomFullContext: {
      deep: true,
      handler (context) {
        if (context.user_id) {
          this.$intercom.call('update', context)
        } else {
          this.$intercom.call('shutdown')
        }
      }
    }
  },
  beforeMount () {
    this.addUserInformationToDataLayer()
  },
  mounted () {
    this.$intercom.boot(this.intercomFullContext)
  },
  methods: {
    closeIeWarning () {
      this.isIe = false
    },
    addUserInformationToDataLayer () {
      if (this.client) {
        this.$gtm.push({
          event: 'user_provided',
          user_id: this.client.id,
          user_email: this.client.attributes.email
        })

        this.$ph.identify(this.client.id, {
          email: this.client.attributes.email,
          app_v1_version: this.$config.APP_VERSION,
        })
      }

      if (this.company) {
        this.$ph.setPersonProperties({
          cw_id: this.company.id,
          cw_name: this.company.attributes.displayName,
          cw_country: this.company.attributes.country,
          cw_created_at: this.company.attributes.createdAt,
          cw_type: this.company.attributes.workspaceType,
          cw_subscription_status: this.company.attributes.subscription_status,
          cw_subscription_plan: this.company.attributes.subscription_plan,
        })

        this.$gtm.push({
          event: 'workspace_provided',
          workspace_id: this.company.id,
          workspace_type: this.company.attributes.workspaceType,
          workspace_has_active_subscription: this.subscription?.attributes.status === SUBSCRIPTION_STATUS_ACTIVE,
          workspace_country: this.company.attributes.country,
        })

        this.$ph.group('workspace', this.company.id, {
          name: this.company.attributes.displayName,
          country: this.company.attributes.country,
          created_at: this.company.attributes.createdAt,
          type: this.company.attributes.workspaceType,
          subscription_status: this.subscription?.attributes.status || null,
          subscription_plan: this.subscription?.attributes.planId || null,
        })
      }
    }
  }
}
