
/**
 * Sorts the given items by making use of Intl.Collator().compare
 * @param {Array} items - The items to sort
 * @param {function} getSortCriterionFromItemFunction - A function that returns the sort criterion from each item in items
 * @returns {Array} - The sorted array
 */
export const sortIntl = (items, getSortCriterionFromItemFunction, language = undefined) => {
  if (typeof getSortCriterionFromItemFunction !== 'function') { getSortCriterionFromItemFunction = item => String(item) }

  return [...items].sort((a, b) =>
    new Intl.Collator(language).compare(
      getSortCriterionFromItemFunction(a) || '',
      getSortCriterionFromItemFunction(b) || ''
    )
  )
}
