import { SIGNUP_PATH, LOGIN_PATH } from '~/utils/constants'

export default async function ({ redirect, store, route }) {
  if (!store.getters['auth/company']) {
    await store.dispatch('auth/fetchCompany')
  }

  if (
    store.getters['auth/isAnonymousWorkspace'] &&
    route.name !== 'builder-edit' &&
    !route.path.startsWith(SIGNUP_PATH) &&
    !route.path.startsWith(LOGIN_PATH)
  ) {
    return redirect(SIGNUP_PATH)
  }
}
